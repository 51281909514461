"use strict";
class ProviderShare {
    constructor() {
        this.init();
    }
    init() {
        const providerClipBtn = document.getElementById('provider-profile-clpbrd-share');
        const provideShareUrl = String(providerClipBtn?.getAttribute('data-clipboard-contents'));
        providerClipBtn?.addEventListener('click', () => {
            navigator.clipboard.writeText(provideShareUrl);
        });
    }
}
new ProviderShare();
